import React from 'react'
import Link from 'gatsby-link'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faFacebook from '@fortawesome/fontawesome-free-brands/faFacebook'
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter'
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram'
import faVimeo from '@fortawesome/fontawesome-free-brands/faVimeo'
import Img from 'gatsby-image'

import {
  DrawerContainer,
  IconList,
  SectionLink,
  SectionLinkContainer,
  ListLink,
  StyledLink,
  Gradient,
  Flex,
  LogoContainer,
} from './menu.css'
import logo from '../../assets/logos/bwvert.svg'
import mlogo from '../../assets/logos/bwhorizontal.svg'

const MenuDrawer = ({ style, hideMenu }) => (
  <DrawerContainer style={{ ...style }}>
    <LogoContainer>
      <Link to="/" activeStyle={{}}>
        <img
          src={logo}
          className="desktopLogo"
          alt="New American Story Project"
          style={{ width: '100%' }}
        />
        <img
          src={mlogo}
          className="mobileLogo"
          alt="New American Story Project"
          style={{ width: '100%', maxWidth: '20rem', maxHeight: '5rem' }}
        />
      </Link>
    </LogoContainer>
    <SectionLinkContainer>
      <Link to="/content?filter=stories" activeStyle={{}} onClick={hideMenu}>
        <SectionLink>Stories</SectionLink>
      </Link>
      <Link to="/content?filter=context" activeStyle={{}} onClick={hideMenu}>
        <SectionLink>Context</SectionLink>
      </Link>
      <Link to="/content?filter=all" activeStyle={{}} onClick={hideMenu}>
        <SectionLink small>View All</SectionLink>
      </Link>
    </SectionLinkContainer>
    <Flex>
      <ListLink>
        <ul>
          <li>
            <StyledLink
              exact
              to="/"
              activeStyle={{
                opacity: 0.7,
              }}
              onClick={hideMenu}
            >
              Homepage
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/about/"
              activeStyle={{
                opacity: 0.7,
              }}
              onClick={hideMenu}
            >
              About NASP
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/action/"
              activeStyle={{
                opacity: 0.7,
              }}
              onClick={hideMenu}
            >
              Take Action
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/collaborators/"
              activeStyle={{
                opacity: 0.7,
              }}
              onClick={hideMenu}
            >
              Collaborators
            </StyledLink>
          </li>
        </ul>
      </ListLink>
      <div>
        <IconList>
          <li>
            <a
              href="https://www.facebook.com/nastoryproject/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} size="lg" fixedWidth />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/nastoryproject"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} size="lg" fixedWidth />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/na_storyproject/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="lg" fixedWidth />
            </a>
          </li>
          <li>
            <a
              href="https://vimeo.com/nastoryproject"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faVimeo} size="lg" fixedWidth />
            </a>
          </li>
        </IconList>
      </div>
    </Flex>
  </DrawerContainer>
)

export default MenuDrawer
