import React from 'react'
import PropTypes from 'prop-types'
import { Consumer } from 'store/createContext'
import Menu from 'components/menu'

const MenuContainer = () => (
  <Consumer>
    {({
      menuOpen,
      showMenu,
      hideMenu,
      toggleMenu,
      menuTitle,
      shareOpen,
      toggleShare,
    }) => (
      <Menu
        menuTitle={menuTitle}
        menuOpen={menuOpen}
        shareOpen={shareOpen}
        showMenu={showMenu}
        hideMenu={hideMenu}
        toggleMenu={toggleMenu}
        toggleShare={toggleShare}
      />
    )}
  </Consumer>
)

MenuContainer.propTypes = {
  // children: PropTypes.node.isRequired,
}

export default MenuContainer
