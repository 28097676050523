import styled from 'styled-components'

export const Container = styled.div`
  background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
`

export const Wrapper = styled.div`
  max-width: 100rem;
  margin: 0 auto;
  display: flex;
  @media only screen and (max-width: 64rem) {
    flex-direction: column;
  }
`

export const Text = styled.p`
  font-family: 'GT Super Display', serif;
  font-size: 3rem;
  font-weight: 400;
  max-width: 20em;
  margin: 0 auto;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
`

export const Chunk = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 3);
  padding: 4rem;
  &:not(:last-child) {
    border-right: 1px solid ${props => props.theme.colors.mediumGray};
  }
  ${props =>
    props.logoChunk &&
    `
    align-items: flex-end;
    @media only screen and (max-width: 80rem) {
      align-items: center;
    }
    @media only screen and (max-width: 64rem) {
      align-items: start;
    }
  `};
  h4 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    max-width: 26rem;
  }
  p {
    font-size: 1rem;
    font-weight: 300;
    max-width: 26rem;
  }
  input {
    font-weight: 300;
    font-size: 1.5rem;
    margin: 0.5rem 0;
    background: none;
    border: none;
    color: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
    width: 100%;
    position: relative;
  }
  button {
    font-size: 0.75rem;
    margin: 0;
    padding: 0;
    margin-bottom: 3rem;
    appearance: none;
    background: none;
    color: ${props => props.theme.colors.white};
    border: none;
    float: right;
    text-decoration: underline;
    transform: translateY(-2rem);
  }
  @media only screen and (max-width: 64rem) {
    width: 100%;
    padding: 3rem 2rem;
    &:not(:last-child) {
      border: none;
      border-bottom: 1px solid ${props => props.theme.colors.mediumGray};
    }
  }
`

export const ListLink = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin-bottom: 0.5rem;
  }
  a {
    color: inherit;
    font-family: ${props => props.theme.fonts.monospace};
  }
`

export const IconList = styled.ul`
  list-style-type: none;
  margin: 0;
  margin-top: 2rem;
  padding: 0;
  display: flex;
  li:not(:last-child) {
    margin-right: 0.75rem;
  }
`

export const Sponsor = styled.div`
  margin-top: 1.5rem;
  margin-right: 1rem;
  a {
    color: inherit;
    font-size: 0.75rem;
    text-transform: uppercase;
  }
`

export const Sponsors = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const LogoDiv = styled.div`
  justify-self: end;
  display: flex;
  img {
    margin-top: -1rem;
    padding-left: 2rem;
    margin-right: 4rem;
    width: 10rem;
  }
  @media only screen and (max-width: 80rem) {
    flex-direction: column;
    img {
      margin: 0;
      padding: 0;
      margin-bottom: 3rem;
    }
  }
  @media only screen and (max-width: 64rem) {
    flex-direction: row;
    img {
      margin-bottom: 0;
      margin-right: 3rem;
      width: 6rem;
    }
  }
`
