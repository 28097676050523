import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share'

import { ShareDrawerContainer, StyledLink, ShareTitle } from './menu.css'

const ShareDrawer = ({ style, location }) => (
  <ShareDrawerContainer style={{ ...style }}>
    <ShareTitle>Share This Page</ShareTitle>
    <TwitterShareButton
      url={`http://newamericanstoryproject.org${location.pathname}`}
    >
      <StyledLink>Twitter</StyledLink>
    </TwitterShareButton>
    <FacebookShareButton
      url={`http://newamericanstoryproject.org${location.pathname}`}
    >
      <StyledLink>Facebook</StyledLink>
    </FacebookShareButton>
    <EmailShareButton
      url={`http://newamericanstoryproject.org${location.pathname}`}
    >
      <StyledLink>Email</StyledLink>
    </EmailShareButton>
  </ShareDrawerContainer>
)

export default ShareDrawer
