import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from './createContext'

// The provider, which holds the page-wide store and its actions.
// Feel free to abstract actions and state away from this file.
class AppProvider extends Component {
  state = {
    menuOpen: true,
    shareOpen: false,
    menuTitle: 'New American Story Project',
    showMenu: () => this.setState({ menuOpen: true, shareOpen: false }),
    hideMenu: () => this.setState({ menuOpen: false }),
    showShare: () => this.setState({ shareOpen: true, menuOpen: false }),
    hideShare: () => this.setState({ shareOpen: false }),
    toggleMenu: () =>
      this.setState({ menuOpen: !this.state.menuOpen, shareOpen: false }),
    toggleShare: () =>
      this.setState({ shareOpen: !this.state.shareOpen, menuOpen: false }),
    setMenuTitle: title => this.setState({ menuTitle: title }),
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppProvider
