import { createGlobalStyle } from 'styled-components'
import GTSuperLightItalicWoff from 'assets/fonts/GT-Super-Display-Light-Italic.woff'
import GTSuperLightItalicWoff2 from 'assets/fonts/GT-Super-Display-Light-Italic.woff2'
import GTSuperLightWoff from 'assets/fonts/GT-Super-Display-Light.woff'
import GTSuperLightWoff2 from 'assets/fonts/GT-Super-Display-Light.woff2'
import GTSuperMediumWoff from 'assets/fonts/GT-Super-Display-Medium.woff'
import GTSuperMediumWoff2 from 'assets/fonts/GT-Super-Display-Medium.woff2'

export default createGlobalStyle`

@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:200,400|IBM+Plex+Sans:300,400,500');

@font-face {
  font-family: 'GT Super Display';
  src: url(${GTSuperLightWoff2}) format('woff2'),
    url(${GTSuperLightWoff}) format('woff');
}
@font-face {
  font-family: 'GT Super Display';
  src: url(${GTSuperLightItalicWoff2})
      format('woff2'),
    url(${GTSuperLightItalicWoff}) format('woff');
  font-style: italic;
}
@font-face {
  font-family: 'GT Super Display';
  src: url(${GTSuperMediumWoff2}) format('woff2'),
    url(${GTSuperMediumWoff}) format('woff');
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  background: #1f1f1f;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  text-rendering: geometricPrecision;
  background: white;
}

*:focus {
  outline: dotted 1px;
}

.svg-inline--fa {
  display: none;
}`
