const theme = {
  colors: {
    black: '#1F1F1F',
    darkGray: '#3F3F3F',
    mediumGray: '#A3A3A3',
    lightGray: '#F2F2F2',
    white: '#ffffff',
    brandGreen: '#4cae69',
    brandYellow: '#f2b143',
    brandOrange: '#d96b38',
    brandBlue: '#378cca',
    highlight: '#fdf0db',
  },
  fonts: {
    serif: '"GT Super Display", georgia, times, serif',
    sansSerif:
      '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif',
    monospace:
      '"IBM Plex Mono", -apple-system, BlinkMacSystemFont, "avenir next", avenir, "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial, sans-serif',
  },
}

export default theme
