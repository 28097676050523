import React from 'react'
import Image from 'gatsby-image'
import Link from 'gatsby-link'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faFacebook from '@fortawesome/fontawesome-free-brands/faFacebook'
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter'
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram'
import faVimeo from '@fortawesome/fontawesome-free-brands/faVimeo'

import {
  Container,
  Chunk,
  ListLink,
  IconList,
  Sponsor,
  Sponsors,
  LogoDiv,
  Wrapper,
} from './footer.css'
import logo from '../../assets/logos/bwvertwhite.svg'

const url =
  'https://newamericanstoryproject.us14.list-manage.com/subscribe/post?u=bf6f20f22ad7bb70eec092cd6&amp;id=c9c440c52a'

const Footer = () => (
  <Container>
    <Wrapper>
      <Chunk logoChunk>
        <LogoDiv>
          <img src={logo} alt="New American Story Project" />
          <div>
            <ListLink>
              <li>
                <Link
                  exact
                  to="/"
                  activeStyle={{
                    opacity: 0.7,
                  }}
                >
                  Homepage
                </Link>
              </li>
              <li>
                <Link
                  to="/content/"
                  activeStyle={{
                    opacity: 0.7,
                  }}
                >
                  All Content
                </Link>
              </li>
              <li>
                <Link
                  to="/about/"
                  activeStyle={{
                    opacity: 0.7,
                  }}
                >
                  About NASP
                </Link>
              </li>
              <li>
                <Link
                  to="/action/"
                  activeStyle={{
                    opacity: 0.7,
                  }}
                >
                  Take Action
                </Link>
              </li>
              <li>
                <Link
                  to="/collaborators/"
                  activeStyle={{
                    opacity: 0.7,
                  }}
                >
                  Collaborators
                </Link>
              </li>
            </ListLink>
            <IconList>
              <li>
                <FontAwesomeIcon icon={faFacebook} size="lg" fixedWidth />
              </li>
              <li>
                <FontAwesomeIcon icon={faTwitter} size="lg" fixedWidth />
              </li>
              <li>
                <FontAwesomeIcon icon={faInstagram} size="lg" fixedWidth />
              </li>
              <li>
                <FontAwesomeIcon icon={faVimeo} size="lg" fixedWidth />
              </li>
            </IconList>
          </div>
        </LogoDiv>
      </Chunk>
      <Chunk>
        <h4>Stay Connected with NASP</h4>
        <p>
          Sign up with your email to receive periodic newsletters and updates
          about NASP.
        </p>
        <MailchimpSubscribe className="subscribe" url={url} />

        <h4>Want to Contribute?</h4>
        <p>
          Email us at info@newamericanstoryproject.org if you would like to
          contribute, collaborate, or support the project.
        </p>
      </Chunk>
      <Chunk>
        <h4>Sponsors</h4>
        <p>These organizations help make NASP possible.</p>
        <Sponsors>
          <Sponsor>
            <a
              href="https://centrolegal.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Centro Legal de la Raza
            </a>
          </Sponsor>
          <Sponsor>
            <a
              href="https://sff.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              SF Foundation
            </a>
          </Sponsor>

          <Sponsor>
            <a
              href="https://www.rexfoundation.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Rex Foundation
            </a>
          </Sponsor>

          <Sponsor>
            <a
              href="http://pantarhea.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Panta Rhea
            </a>
          </Sponsor>
          <Sponsor>
            <a
              href="https://aquent.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Aquent LLC
            </a>
          </Sponsor>
          <Sponsor>
            <a
              href="https://isabelallende.org/en/story"
              target="_blank"
              rel="noopener noreferrer"
            >
              Isabel Allende Foundation
            </a>
          </Sponsor>
          <Sponsor>
            <a
              href="https://cgrs.uchastings.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Center for Gender & Refugee Studies
            </a>
          </Sponsor>
          <Sponsor>
            <a
              href="https://www.contentful.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contentful
            </a>
          </Sponsor>
        </Sponsors>
      </Chunk>
    </Wrapper>
  </Container>
)

export default Footer
