import styled from 'styled-components'
import Link from 'gatsby-link'

export const Fixed = styled.div`
  position: fixed;
  top: 0;
  left: 4rem;
  right: 4rem;
  border: 1px solid;
  background: ${props => props.theme.colors.white};
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  font-family: 'IBM Plex Mono', sans-serif;
  color: ${props => props.theme.colors.black};
  border-top: none;
  border-bottom: none;
  max-width: 100rem;
  margin: 0 auto;
  .svg-inline--fa {
    display: initial;
  }
  @media only screen and (max-width: 48rem) {
    left: 2rem;
    right: 2rem;
  }
  @media only screen and (max-width: 32rem) {
    left: -1px;
    right: -1px;
  }
`
export const Background = styled.div`
  background: ${props => props.theme.colors.white};
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  border-bottom: 1px solid black;
`

export const Close = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1;
`

export const OutOfBounds = styled.div`
  background: ${props => props.theme.colors.white};
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 10rem;
  position: absolute;
  z-index: -1;
  transform: translateY(-100%);
`

export const DrawerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  background: ${props => props.theme.colors.white};
  border: 1px solid;
  justify-content: space-between;
  z-index: -2;
  border-top: none;
  .mobileLogo {
    display: none;
  }
  @media only screen and (max-width: 48rem) {
    flex-direction: column;
    .desktopLogo {
      display: none;
    }
    .mobileLogo {
      display: block;
    }
  }
  @media only screen and (max-width: 32rem) {
    top: 0;
    padding-top: 3rem;
    height: 100vh;
  }
`

export const ShareDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: auto;
  right: -1px;
  background: ${props => props.theme.colors.white};
  border: 1px solid;
  padding: 2rem 3rem;
  justify-content: space-between;
  align-items: flex-start;
  z-index: -2;
  border-top: none;
`

export const PageTitle = styled.h2`
  position: absolute;
  left: 50%;
  top: 0.75rem;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: 400;
  font-family: ${props => props.theme.fonts.sansSerif};

  @media only screen and (max-width: 54rem) {
    display: none;
  }
  @media only screen and (max-width: 62rem) {
    position: static;
    transform: translate(0%, 0%);
  }
`

export const ListLink = styled.div`
  padding: 1rem;
  border-right: 1px solid;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin-bottom: 0.75rem;
    text-align: center;
    @media only screen and (max-width: 48rem) {
      text-align: left;
    }
  }
  a {
    color: inherit;
    font-family: ${props => props.theme.fonts.monospace};
  }
  @media only screen and (max-width: 48rem) {
    padding: 2rem;
    justify-content: flex-start;
    li {
      margin-bottom: 1.5rem;
    }
  }
  @media only screen and (max-width: 32rem) {
  }
`

export const SectionLink = styled.span`
  font-size: ${props => (props.small ? '1rem' : '2.5rem')};
  font-family: ${props =>
    props.small ? props.theme.fonts.monospace : props.theme.fonts.serif};
`

export const SectionLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  border-right: 1px solid;
  a {
    text-align: center;
    height: calc(100% / 3);
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem;
    flex: 1 0 auto;
  }
  a:not(:last-child) {
    border-bottom: 1px solid;
  }
  @media only screen and (max-width: 48rem) {
    border: none;
    a {
      height: 5rem;
    }
  }
`

export const IconList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  font-size: 1.25rem;
  li {
    padding: 0.25rem 0.5rem;
    margin: 0.25rem 0.5rem;
    &:last-child {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }
    &:first-child {
      padding-top: 0.5rem;
      margin-top: 0.5rem;
    }
  }
  a {
    color: inherit;
  }
  @media only screen and (max-width: 100rem) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 32rem) {
    font-size: 1.25rem;
  }
`

export const Divider = styled.span`
  @media only screen and (max-width: 32rem) {
    display: none;
  }
`

export const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 1rem 2rem;
  margin: 1px;
  font-family: ${props => props.theme.fonts.monospace};
  font-size: 0.75rem;
  text-transform: uppercase;
  ${props =>
    props.nextStory &&
    '@media only screen and (max-width: 32rem) {display: none;}'};
  &:hover {
    background: ${props => props.theme.colors.lightGray};
  }
`

export const StyledLink = styled(Link)`
  display: inline-block;
  color: inherit;
  text-decoration: none;
  background-image: linear-gradient(
    ${props =>
      props.theme.colors.black &&
      `${props.theme.colors.black}, ${props.theme.colors.black}`}
  );
  padding-bottom: 0.25em;
  margin-bottom: 0.25rem;
  background-position: 0 1.25em;
  background-repeat: repeat-x;
  background-size: 1px 1px;
`

export const ShareTitle = styled.h4`
  font-weight: 400;
  font-family: ${props => props.theme.fonts.sansSerif};
  margin-top: 0;
  margin-bottom: 0.75rem;
`

export const Gradient = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.5));
`

export const Flex = styled.div`
  display: flex;
  width: 60%;
  @media only screen and (max-width: 48rem) {
    width: 100%;
    border-top: 1px solid;
  }
  @media only screen and (max-width: 32rem) {
    height: 50%;
  }
`

export const LogoContainer = styled.div`
  padding: 1rem;
  width: 25%;
  border-right: 1px solid;
  @media only screen and (max-width: 48rem) {
    border: none;
    border-top: 1px solid;
    order: 10;
    width: 100%;
    img {
      margin: 0 auto;
    }
  }
`
