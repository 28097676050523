import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
// import Head from 'components/head'
// import Header from 'components/header'
import Menu from 'containers/menu'
import Footer from 'components/footer'

import theme from 'theme.js'
import GlobalStyle from 'global.css.js'

const Layout = ({ data, children }) => (
  <ThemeProvider theme={theme}>
    <div>
      <GlobalStyle />
      {/*<Head />*/}
      {/*<Header title={data.site.siteMetadata.siteTitle} />*/}
      <Menu />
      {children}
      <Footer />
    </div>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
}

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          id
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutWithQuery
