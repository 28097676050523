// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-action-jsx": () => import("./../../../src/pages/action.jsx" /* webpackChunkName: "component---src-pages-action-jsx" */),
  "component---src-pages-collaborators-jsx": () => import("./../../../src/pages/collaborators.jsx" /* webpackChunkName: "component---src-pages-collaborators-jsx" */),
  "component---src-pages-content-jsx": () => import("./../../../src/pages/content.jsx" /* webpackChunkName: "component---src-pages-content-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-videos-jsx": () => import("./../../../src/pages/videos.jsx" /* webpackChunkName: "component---src-pages-videos-jsx" */),
  "component---src-templates-collaborator-collaborator-jsx": () => import("./../../../src/templates/collaborator/collaborator.jsx" /* webpackChunkName: "component---src-templates-collaborator-collaborator-jsx" */),
  "component---src-templates-context-context-jsx": () => import("./../../../src/templates/Context/context.jsx" /* webpackChunkName: "component---src-templates-context-context-jsx" */),
  "component---src-templates-story-story-jsx": () => import("./../../../src/templates/Story/story.jsx" /* webpackChunkName: "component---src-templates-story-story-jsx" */)
}

